function serialnumbersRange({ serialnumbers }) {
  // Get the first and last serialnumber codes to display
  const serialnumbersCodes = serialnumbers?.map(
    (serialnumber) => serialnumber.code
  );
  const firstSerialnumber = serialnumbersCodes?.[0];
  const lastSerialnumber = serialnumbersCodes?.[serialnumbersCodes.length - 1];
  // Get the numeric part of the serialnumber code
  const firstSnNumeric = firstSerialnumber?.match(/\d+/);
  const lastSnNumeric = lastSerialnumber?.match(/\d+/);
  const serialnumbersLength =
    firstSnNumeric && lastSnNumeric
      ? Number(lastSnNumeric[0]) - Number(firstSnNumeric[0]) + 1
      : 0;
  const serialnumbersRange =
    serialnumbersCodes?.length > 0
      ? ` ${firstSerialnumber}${
          serialnumbersCodes.length > 1
            ? (serialnumbersLength === serialnumbers?.length
                ? " ... "
                : " !!! ") + lastSerialnumber
            : ""
        }`
      : "";

  return serialnumbersRange;
}

function serialnumbersCount({ serialnumbers }) {
  return serialnumbers?.length || 0;
}

export { serialnumbersRange, serialnumbersCount };
